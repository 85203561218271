@font-face {
  font-family: 'Maison';
  src: url('fonts/MaisonNeueExtended-Black.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Maison';
  src: url('fonts/MaisonNeueExtended-Book.otf');
  font-weight: normal;
  font-style: normal;
}
//Переменные цветов
$white: #ffffff;
$dark: #000000;
$red: #FF0000;
*{padding: 0;margin: 0;border: 0;}
*,*:before,*:after{-moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;box-sizing: border-box;}
:focus,:active{outline: none;}
a.text, a.text:hover, a.text:active, a.text:visited, a.text:focus{
  color: $dark;
}
a:focus,a:active{outline: none;}
html,body{
  height: 100%;
  //width: 100%;
  line-height: 1;
  font-size: 10px;
  font-family: Maison, sans-serif;
}
button{cursor: pointer;}
/////////////////////////////////////////////
#root{
  height: -webkit-fill-available;
}
.flex{
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .main{
    padding: 8px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      text-align: center;
      .main__phone {
        color: $dark;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 12px;
        letter-spacing: -0.02em;
        text-decoration-line: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: url("./images/call.svg");
          margin-right: 8px;
        }
      }
    }

    p{
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      letter-spacing: -0.02em;
    }

    .download{
      text-align: center;
      width: 100%;
      &.qr{
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        img{
          width: 144px;
          margin-top: 0;
        }
        p{margin: 0 0 0 2%}
      }
      img{
        width: 40%;
        margin-top: 8px;
      }
    }

    .btn-group{
      width: 80%;
      .btn{
        background: $dark;
        width: 100%;
        color: $white;
        border-radius: 4px;
        margin-top: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.02em;
        img{margin-right: 8px;}
      }

      iframe{
        width: 100%;
        margin-top: 1vh;
        border: 3px solid $dark;
      }
    }

    //marginsMobile
    .logo,.main__phone,p{margin-top: 3vh;}

  }
  footer{
    width: 100%;
    background: linear-gradient(108.82deg, #080808 0%, #6A0408 100%);
    box-shadow: 0px -4px 40px -15px #650408;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 32px;
    margin-top: 5%;
    .logo{
      flex: 0 0 35%;
      img{width: 80%}
    }

    .footer__link {
      &:nth-child(2){flex: 0 0 65%;}
      &:nth-child(3){
        flex: 0 0 100%;
        margin-top: 4%;
      }
      .footer__link_item {
        color: $white;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
        margin-top: 4%;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:nth-child(1){margin-top: 0;}
        &:before{
          background-size: 20px 20px;
          background-repeat: no-repeat;
          display: inline-block;
          width: 20px;
          height: 20px;
          content:"";
        }
        &.phone:before{
          background-image: url("./images/phone.svg");
          margin-right: 8px;
        }
        &.clock:before{
          background-image: url("./images/clock.svg");
          margin-right: 8px;
        }
        &.geo:before{
          background-image: url("./images/geo.svg");
          margin-right: 8px;
        }
      }
    }
  }
}


@media (max-width: 320.1px) {
  .flex{
    .main{
      .logo,.main__phone,p{margin-top: 1.5vh;}
    }
  }
  footer{
    padding: 16px !important;
    .logo{
      display: none;
    }
  }
}
@media (max-width: 599.99999999px) {
  iframe{display: none}
}

@media (max-width: 1023px) {
  footer{
    .footer__link_item{
      &.desktop{display: none !important}
    }
  }
}
@media (min-width: 1024px) {
  footer{
    .footer__link{
      &.mobile{
        display: none;
      }
    }
  }
}


@media (min-width: 390px) and (max-width: 599.99999999px){
  .logo {
    width: 100%;
    img{width: 60%}
  }
  .main__phone{
    font-size: 32px ;
  }
  a,p,{
    font-size: 18px ;
    line-height: 18px ;
  }
}

@media (min-width: 600px) and (max-width: 1023.99px) {
  .flex{
    .main{
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 4%;
      .logo {
        flex: 0 0 55%;
        text-align: left;
        margin-top: 0!important;
        img{width: 90%;}

        .main__phone {
          margin-top: 5%;
          font-size: 32px;
          justify-content: flex-start;
        }
      }
      p{
        font-size: 20px;
        line-height: 17.5px;
        font-weight: bold;
      }
      .download{
        flex: 0 0 45%;
        width: auto;
        margin-top: 0;
        text-align: right;
        p{
          text-align: right;
          margin-top: 0;
        }
        img{width: 60%;}
      }

      .btn-group{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        p{
          flex: 0 0 100%;
          margin-top: 4vh;
        }
        .btn{
          height: 40px;
          flex:  0 0 33%;
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    footer{
      margin-top: 0;
      .footer__link {
        .footer__link_item {
          font-size: 32px;
          line-height: 48px;
          &:before{
            background-size: 32px 32px;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .flex{
    .main{
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 2%;
      .logo {
        order: 1;
        flex: 0 0 35%;
        text-align: left;
        margin-top: 0!important;
        img{width: 90%;}

        .main__phone {
          margin-top: 4%;
          font-size: 32px;
          justify-content: flex-start;
        }
      }
      p{
        font-size: 20px;
        line-height: 17.5px;
        font-weight: bold;
      }
      .download{
        order: 3;
        flex: 0 0 50%;
        width: auto;
        margin-top: 0;
        text-align: left;
        p{
          margin-top: 10%;
          text-align: left;
        }
        img{width: 40%;}
      }

      .btn-group{
        order:2;
        flex: 0 0 60%;
        width: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        iframe{
          //min-height: 200px;
          margin-top: 0;
        }
        p{
          display: none;
        }
        .btn{
          height: 40px;
          flex:  0 0 33%;
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    footer{
      margin-top: 0;
      display: flex;
      .logo{
        flex: 0 0 25%;
        img{width: 80%}
      }
      .footer__link {
        width: 100%;
        .footer__link_item {
          margin-top: 32px;
          font-size: 32px;
          line-height: 32px;
          flex:0 0 100%;
          &:before{
            background-size: 32px 32px;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}


//
//
//@media (min-width: 1366px) {
//  .flex{
//    .main{
//      margin-top: 5%;
//      .logo{width: 50%;}
//      .main__phone{
//        font-size: 64px;
//        line-height: 64px;
//      }
//      .mobile, .download, .btn-group{
//        display: none;
//      }
//      p{
//        font-size: 48px;
//        line-height: 48px;
//      }
//    }
//    footer{
//      flex-direction: row;
//      padding: 5%;
//      .logo{width: 30%; text-align: center}
//      .footer__link {
//        margin-left: 5%;
//        .footer__link_item {
//          width: 100%;
//          font-size: 40px;
//          line-height: 40px;
//          margin-top: 12px;
//        }
//      }
//    }
//  }
//}